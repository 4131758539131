<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      .text-center 開啟頁面中, 請稍等
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
export default {
  name: 'GaAnalysisReport',
  data () {
    return {
      title: "數據分析 / 數據分析報表",
      items: [
        {
          text: "數據分析",
          href: "/dashboard",
        },
        {
          text: "數據分析報表",
          active: true,
        }
      ],
    };
  },
  created () {
    setTimeout(() => {
      window.open("https://lookerstudio.google.com/reporting/d3a2452a-84aa-42c5-bb2f-87621f1dc1e8/page/p_3cvimufdod", "_blank")
    }, 1500);
  },
  computed: {

  },
  methods: {

  },
  components: {
    Layout,
    PageHeader
  }
};
</script>

<style lang="sass" scoped>
.card-header
  background: #ffffff
iframe
  width: 100%
  height: 100%
  min-height: calc(100vh - 125px)
</style>
